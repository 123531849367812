import axios from "axios"

const service = axios.create({
    timeout: 5000
})

// request拦截器
service.interceptors.request.use(config => {

    config.url = 'https://api.hbabxx2008.com' + config.url

    // if(process.env.NODE_ENV === 'development'){
    //     // 开发环境
    // }else{
    //     // 生产环境
    // }

    config.headers = {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json',
        }
    return config;

}, error => {
    Promise.reject(error)
})

service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        switch (error.response.status) {
            case 401:
                break
            case 500:
                break
            default:
        }
        return Promise.reject(error.response)
    }
)

export default service