import request from '@/config/request'
// import qs from 'querystring'

//首页
export function pcIndex(params) {
    return request({
        url: '/api/App_Course/pcIndex',
        method: 'get',
        data: params
    })
}

// 新闻咨询
export function splitListNews(params) {
    return request({
        url: '/api/App_News/splitListNews',
        method: 'get',
        params: params
    })
}

// 新闻咨询详情
export function newsById(params) {
    return request({
        url: '/api/App_News/getNewsById',
        method: 'get',
        params: params
    })
}


// 获取全部分类
export function industry(params) {
    return request({
        url: '/api/App_Industry/getIndustry',
        method: 'get',
        params: params
    })
}

// 获取课程分类
export function vuesDictionaryByKey(params) {
    return request({
        url: '/api/Sys_Dictionary/getVuesDictionaryByKey',
        method: 'get',
        params: params
    })
}

// 选课中心课程分页
export function splitListCourse(params) {
    return request({
        url: '/api/App_Course/splitListCourse',
        method: 'get',
        params: params
    })
}

// 观看课程
export function watchCourse(params) {
    return request({
        url: '/api/V_Authorize/watchCourse',
        method: 'get',
        params: params
    })
}

// 课程详情
export function courseById(params) {
    return request({
        url: '/api/App_Course/getCourseById',
        method: 'get',
        params: params
    })
}

// 名师堂分页
export function splitListTeacher(params) {
    return request({
        url: '/api/App_Teacher/splitListTeacher',
        method: 'get',
        params: params
    })
}

// 教师视频详情
export function teacherById(params) {
    return request({
        url: '/api/App_Teacher/getTeacherVideoById',
        method: 'get',
        params: params
    })
}

// 课程详情 获取推荐的老师
export function tjTeacher(params) {
    return request({
        url: '/api/App_Teacher/getTjTeacher',
        method: 'get',
        params: params
    })
}

// 在线留言
export function submitMessage(data) {
    return request({
        url: '/api/App_Message/submitMessage',
        method: 'post',
        data
    })
}

// 获取协议 key 传入 关于我们 用户协议 隐私政策
export function agreement(params) {
    return request({
        url: '/api/App_Agreement/getAgreement',
        method: 'get',
        params
    })
}

// 热门真题
export function splitListPaper(params) {
    return request({
        url: '/api/App_ExercisesPaper/splitListPaper',
        method: 'get',
        params
    })
}

// 问题
export function allQuestion(params) {
    return request({
        url: '/api/App_Question/getAllQuestion',
        method: 'get',
        params
    })
}

// PC推荐的学员评论
export function tjReview(params) {
    return request({
        url: '/api/V_Review/getTjReview',
        method: 'get',
        params
    })
}
